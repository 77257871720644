import * as React from "react";
import { Link } from "gatsby";
import Layout from '../../../components/Layout';
import SEOImage from '../../../images/digital-marketing-seo2.jpg';
import SEOAudit from '../../../images/digital-marketing-seo-audit.jpg';
import KeywordResearch from '../../../images/digital-marketing-keyword-research.jpg';
import ContentMarketing from '../../../images/digital-marketing-content-marketing.jpg';
import SEO from '../../../components/Seo';
import CTA from "../../../components/CTA";

const SearchEngineOptimization = () => {

    return(
        <Layout>
            <SEO title="SEO for Healthcare" keywords="HealthOne Digital, search engine optimization for healthcare, seo for healthcare, keyword research, content marketing, seo site audit"/>
            <section>
                <div className="pt-10 bg-blue-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
                    <div className="mx-auto max-w-7xl lg:px-8">
                        <div className="lg:grid lg:grid-cols-1 lg:gap-8">
                            <div className="mx-auto px-4 sm:px-6 sm:text-center lg:px-0 lg:text-center lg:flex lg:items-center">
                                <div className="py-8 sm:py-8 lg:py-24">
                                    <h1 className="mt-4 mb-5 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                         <span className="block">Search Engine Optimization</span> 
                                         <span className="block mt-0 lg:mt-2">For Healthcare</span>
                                    </h1>
                                    <span className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                                        <Link className="hover:text-white" to="/services">SERVICES</Link> 
                                        <span> / </span> 
                                        <Link className="hover:text-white" to="/services/digital-marketing">DIGITAL MARKETING</Link>
                                        <span> / </span>
                                        SEO 
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Content */}
           

            <section>
                <div className="bg-white overflow-hidden py-8 lg:pt-16 lg:pb-8">
                    <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>

                        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
                            <div>
                                <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-blue-900 sm:text-4xl">The Importance of SEO for Healthcare</h2>
                            </div>
                        </div>

                        <div className="mt-2 lg:grid lg:grid-cols-2 lg:gap-8">

                            <div className="relative lg:row-start-1 lg:col-start-2">
                                <svg className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
                                    <defs>
                                        <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                            <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                        </pattern>
                                    </defs>
                                    <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
                                </svg>
                                <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                                <figure>
                                    <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                                        <img className="rounded-lg shadow-lg object-cover object-center" src={SEOImage} alt="Mobile device showing the google search home page" width="1184" height="1376"/>
                                    </div>
                                </figure>
                                </div>
                            </div>

                            <div className="mt-8 lg:mt-0">
                                <div className="mt-6 text-gray-500 space-y-6">
                                    <p className="text-lg">
                                    The majority of people (70%) now search online for their medical information. It’s the first place they go when researching symptoms, treatments and healthcare providers. 
                                    </p>
                                    <p className="text-lg">
                                    Your practice should appear on the first page of search results if someone searches for you specifically. This is where the importance of a Local SEO strategy comes in.
                                    </p>
                                </div>
                         
                                <div className="mt-10 text-gray-500 space-y-6">
                                    <h3 className="mt-5 text-3xl leading-8 font-extrabold tracking-tight text-blue-900">Local SEO</h3>
                                    <p className="text-lg">
                                        Local SEO is exactly what it sounds like and is imperative if you own a practice with a physical location. The aim of local SEO is to ensure your practice shows up when someone searches for a healthcare provider in your area.
                                    </p>
                                    <p className="text-lg">
                                        Think back to the last time you searched for a physical store’s location. You likely typed in the words “near me” after the name of the store. If you did this, you’re in the vast majority of people who search online. 82 percent of smartphone shoppers conduct ‘near me’ searches. (Search Engine Land, 2018).
                                    </p>
                                    <p className="text-lg">
                                        This “near me” part of the search, aka proximity, is now the number one ranking factor in Local SEO. We also ensure when patients find you on google, your google business listing is correct and up to date.
                                    </p>
                                    <p className="text-lg">
                                    This is imperative as your google business listing consists of your practice name, address, phone number, hours of operation and patient reviews. Apart from google, we also ensure your listing information is correct on Bing Places (Mircosoft’s search engine) and Apple Maps.
                                    </p>
                                </div>

                                <div className="mt-10 text-gray-500 space-y-6">
                                    <h3 className="mt-5 text-3xl leading-8 font-extrabold tracking-tight text-blue-900">Organic SEO</h3>
                                    <p className="text-lg">
                                        Venturing beyond your local SEO, if your practice website has:
                                    </p>
                                    <ul className="text-lg list-disc space-y-3 ml-5">
                                        <li>Medical Information</li>
                                        <li>A Medical / Wellness Blog</li>
                                        <li>Offers Digital Products/Software</li>
                                        <li>Contains Video or Informative Media Content</li>
                                    </ul>
                                    <p className="text-lg">
                                    You require a wider ranging Organic SEO strategy. Organic SEO is a term that comprises many related strategies that help improve your search engine ranking based on your website's content. These strategies include a site audit, keyword research, content marketing and link building.
                                    </p>
                                  
                                </div>
                           
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="relative bg-white py-4">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                        <div className="relative sm:py-16 lg:py-0">
                            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                                <svg className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
                                <defs>
                                    <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
                                </svg>
                            </div>
                            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                                <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                                    <img className="absolute inset-0 h-full w-full object-cover" src={SEOAudit} alt=""/>
                              
                                </div>
                            </div>
                        </div>

                        <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                            <div className="pt-12">
                                <h3 className="text-3xl text-blue-900 font-extrabold tracking-tight sm:text-3xl">
                                    Website SEO Audit
                                </h3>
                                <div className="mt-6 text-gray-500 space-y-6">
                                    <p className="text-lg">
                                    Before developing an SEO strategy, we carry out a full technical SEO audit of your website to see where it currently stands and address any issues which could lead to problems down the road. We review your google analytics, your websites pages to ensure there are no broken links and measure your site's performance.
                                    </p>
                                    <p className="text-lg">
                                        We also make sure all your website pages have proper titles, descriptions and meta tags. Only once these issues are addressed, do we move forward with your SEO action plan.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="relative bg-white py-4">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                        <div className="relative sm:py-16 lg:py-0">
                            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                                <svg className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
                                <defs>
                                    <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
                                </svg>
                            </div>
                            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                                <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                                    <img className="absolute inset-0 h-full w-full object-cover" src={KeywordResearch} alt="Group of people sitting around a table with laptops discussing a presentation."/>
                                </div>
                            </div>
                        </div>

                        <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                            <div className="pt-12">
                                <h3 className="mt-8 text-3xl text-blue-900 font-extrabold tracking-tight sm:text-3xl">
                                    Keyword Research
                                </h3>
                                <div className="mt-6 text-gray-500 space-y-6">
                                    <p className="text-lg">
                                    Keywords help you appear in search results for search phrases associated with your Healthcare Practice. Keyword Research is the process of finding keywords that users are searching for when trying to find healthcare providers similar to yours.
                                    </p>
                                    <p className="text-lg">
                                    The process of researching keywords involves analyzing competitors keywords, researching keyword volume, and ultimately determining the cost associated with keywords which are doing well in your particular healthcare niche.
                                    </p>
                                    <p className="text-lg">
                                    It's then important to take these findings and tailor the content on your website to include keywords which are ranking high and your patients are searching for. This is crucial to drive more traffic to your website. These keywords can also be used to run Pay Per Click Ad Campaigns alonside your Organic SEO efforts.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="relative bg-white pt-4 pb-16">
                    <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                        <div className="relative sm:py-16 lg:py-0">
                            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                                <svg className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
                                <defs>
                                    <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
                                </svg>
                            </div>
                            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                                <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                                    <img className="absolute inset-0 h-full w-full object-cover" src={ContentMarketing} alt="Group of people pointing at a laptop screen."/>
                                </div>
                            </div>
                        </div>

                        <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
                            <div className="pt-12">
                                <h3 className="mt-8 text-3xl text-blue-900 font-extrabold tracking-tight sm:text-3xl">
                                    Content Marketing & Link Building
                                </h3>
                                <div className="mt-6 text-gray-500 space-y-6">
                                    <p className="text-lg">
                                    Being seen as a reliable source of healthcare information can dramatically increase your search engine rankings and website traffic. As a healthcare professional, you have incredible knowledge about your specific niche and you can share that knowledge through informative blog posts, short videos and long form content.
                                    </p>
                                    <p className="text-lg">
                                    Your patients will find this information very valuable. You can also further promote this content thorough various social media channels. Learn more about our social media marketing services here.
                                    </p>
                                    <p className="text-lg">
                                    Other websites may also find this information valuable and link to your content further increasing your search engine rankings. As more websites link to your content, the more authoritative your content becomes.
                                    </p>
                                    <p className="text-lg">
                                    This is called Link Building. It is essentially getting other websites to link to your content. Link Building is a process which takes time but is very worthwhile if you are producing content and want to rank higher in search engines and drive organic traffic to your website.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           


            {/* CTA */}
           <CTA/>
        </Layout>
    )
}

export default SearchEngineOptimization;